{
  "account": {
    "active": "Active",
    "available": "Available",
    "balanceDistribution": "Balance Distribution",
    "cantLoadDetails": "Unfortunately we couldn't load the account details at this time. Please try again later.",
    "debonding": "Debonding",
    "emptyTokenList": "This account holds no {{spec}} {{description}}.",
    "emptyTransactionList": "There are no transactions on record for this account.",
    "emptyTokenTransferList": "There are no token transfers on record for this account.",
    "failedToLookUpTickers": "We don't have the price for: {{tickers}}",
    "firstActivity": "First activity",
    "ERC20": "ERC-20",
    "ERC721": "ERC-721",
    "listTitle": "Accounts",
    "noTokens": "This account holds no tokens",
    "noBalances": "This account currently has no balances.",
    "notStaking": "There are currently no active delegations.",
    "notDebonding": "There are no debonding delegations for this account.",
    "showMore": "+ {{counter}} more",
    "sizeTooltip": "This account is indicated as an {{size}} account based on sum of assets they own.",
    "startStaking": "Start staking now",
    "title": "Account",
    "transactionsListTitle": "Account Transactions",
    "totalBalance": "Total Balance",
    "totalValue": "Total {{value}}"
  },
  "activeAccounts": {
    "title": "Active Accounts",
    "tooltip": "{{value, number}} accounts"
  },
  "analyticsConsent": {
    "privacyPolicy": "Privacy Policy",
    "settings": "Privacy settings",
    "text": "At Oasis Foundation we believe in your privacy, so you can choose to browse our site without any tracking or by clicking “{{acceptButtonLabel}}”, you help us to improve our site and help us grow our ecosystem. View our <PrivacyPolicyLink>Privacy Policy</PrivacyPolicyLink> for more information.",
    "blockedText": "Our analytics tracking failed to load. Therefore, you will continue using the site with user tracking disabled. If you are willing to accept tracking please pause your adblocker and reload the page to accept tracking. View our <PrivacyPolicyLink>Privacy Policy</PrivacyPolicyLink> for more information.",
    "acceptButtonLabel": "Accept",
    "declineButtonLabel": "Decline",
    "reloadButtonLabel": "Reload page",
    "closeButtonLabel": "Close"
  },
  "blocks": {
    "latest": "Latest Blocks",
    "title": "Blocks",
    "viewingFirst": "You are viewing the first block",
    "viewingLatest": "You are viewing the latest block",
    "viewNext": "View next block",
    "viewPrevious": "View previous block"
  },
  "block": {
    "gasUsed": "{{value, number}} | {{percentage, number}}",
    "unlimited": "(unlimited)"
  },
  "banner": {
    "buildStaging": "Please note this is an internal launch meant to gather your feedback.",
    "buildPreview": "Please note this is an experimental build of {{appTitle}} and that data that is shown might be incorrect."
  },
  "clipboard": {
    "label": "Copy to clipboard",
    "success": "Copied!"
  },
  "coinGeckoReferral": "Data from <CoinGeckoLink>CoinGecko</CoinGeckoLink>",
  "common": {
    "address": "Address",
    "age": "Age",
    "amount": "Amount",
    "balance": "Balance",
    "block": "Block",
    "blockSizeLimit": "Block size limit",
    "bytes": "{{value, number}}",
    "cancel": "Cancel",
    "collection": "Collection",
    "consensusOnline": "Consensus Online",
    "consensusOutOfDate": "Consensus out of date",
    "copy": "Copy",
    "data": "Data",
    "debonding": "Debonding",
    "details": "Details",
    "emerald": "Emerald",
    "epoch": "Epoch",
    "cipher": "Cipher",
    "consensus": "Consensus",
    "eth": "ETH",
    "events": "Events",
    "failed": "Failed",
    "fiatValue": "Fiat Value",
    "fiatValueInUSD": "{{value, currency}}",
    "fill": "Fill",
    "formattedBlockTimestamp": "{{timestamp, dateTime}} ({{distance}})",
    "formattedDateTime": "{{timestamp, dateTime}}",
    "from": "From",
    "gasUsed": "Gas Used",
    "gasLimit": "Gas Limit",
    "gasPrice": "Gas Price",
    "hash": "Hash",
    "height": "Height",
    "hide": "Hide",
    "id": "ID",
    "invalidVotes": "Invalid votes",
    "layer": "Layer",
    "loadMore": "Load more",
    "lessThanAmount": "&lt; {{value, number}} <TickerLink />",
    "missing": "n/a",
    "name": "Name",
    "network": "Network",
    "nft": "NFT",
    "nfts": "NFTs",
    "nonce": "Nonce",
    "not_defined": "Not defined",
    "oasis": "Oasis",
    "owned": "Owned",
    "paratime": "Paratime",
    "parentheses": "({{subject}})",
    "percentage": "Percentage",
    "proposal": "Proposal",
    "proposer": "Proposer",
    "rank": "Rank",
    "select": "Select",
    "size": "Size",
    "sapphire": "Sapphire",
    "shares": "Shares",
    "show": "Show",
    "smartContract": "Smart Contract",
    "smartContract_short": "Contract",
    "staked": "Staked",
    "stateRoot": "StateRoot",
    "staking": "Staking",
    "submitter": "Submitter",
    "success": "Success",
    "status": "Status",
    "ticker": "Ticker",
    "timestamp": "Timestamp",
    "title": "Title",
    "to": "To",
    "token": "Token",
    "tokenInstance": "TokenID [<InstanceLink />] <TickerLink/>",
    "tokens": "Tokens",
    "totalVotes": "Total votes",
    "transactions": "Transactions",
    "transactionAbbreviation": "Txs",
    "fee": "Fee",
    "transfers": "Transfers",
    "type": "Type",
    "quantity": "Quantity",
    "unknown": "Unknown",
    "valueLong": "{{value, number}}",
    "valueInToken": "{{value, number}} {{ticker}}",
    "roundedValueInToken": "{{value, number}}… <TickerLink />",
    "valueInTokenWithLink": "{{value, number}} <TickerLink />",
    "view": "View",
    "viewAll": "View all",
    "vote": "Vote",
    "voter": "Voter",
    "votes": "Votes",
    "paraTimeOnline": "ParaTime Online",
    "paraTimeOutOfDate": "ParaTime Out of date",
    "recipient": "Recipient",
    "mainnet": "Mainnet",
    "testnet": "Testnet",
    "valuePair": "{{value, number}}"
  },
  "contract": {
    "code": "Code",
    "copyButton": "Copy {{subject}}",
    "creationByteCode": "Creation ByteCode",
    "creator": "Creator",
    "createdAt": "at",
    "noCode": "There is no bytecode on record for this account. (Are you sure this is a contract?)",
    "title": "Contract",
    "runtimeByteCode": "Runtime ByteCode",
    "verification": {
      "title": "Verification",
      "isVerified": "Verified",
      "isNotVerified": "Unverified",
      "openInAbiPlayground": "Interact in <AbiPlaygroundLink>ABI Playground</AbiPlaygroundLink>",
      "openInSourcify": "Open in <SourcifyLink>Sourcify</SourcifyLink>",
      "verifyInSourcify": "Verify through <SourcifyLink>Sourcify</SourcifyLink>",
      "explainVerificationDelay": "If you have just verified a contract, it should take a few minutes to update here."
    }
  },
  "consensusSnapshot": {
    "blockHeight": "Block Height <BlockHeight />",
    "title": "Consensus Snapshot",
    "totalCirculation": "This is <StakedPercentage /> of the total circulation"
  },
  "currentEpoch": "Current Epoch",
  "dapps": {
    "wrose": {
      "description": "Looking to wrap or unwrap your wROSE?",
      "label": "Use our ROSE (un)wrapper"
    }
  },
  "networkProposal": {
    "close": "Closed",
    "closeTooltip": "Voting ended on epoch shown.",
    "create": "Created",
    "createTooltip": "Voting created on epoch shown.",
    "deposit": "Deposit",
    "failedToLoadAllVotes": "Failed to load all the votes, number might be incomplete!",
    "thereAreNoVotes": "No votes have been registered.",
    "handler": "Title",
    "id": "ID",
    "listTitle": "Network Change Proposals",
    "state": {
      "active": "Active",
      "failed": "Failed",
      "passed": "Passed",
      "rejected": "Rejected"
    },
    "searchForVoter": "Search for voter",
    "type": {
      "upgrade": "Upgrade",
      "parameterUpgrade": "Parameter upgrade",
      "cancellation": "Cancellation"
    },
    "vote": {
      "yes": "Yes",
      "no": "No",
      "abstain": "Abstained",
      "all": "All votes"
    }
  },
  "nft": {
    "accountCollection": "ERC-721 Tokens",
    "collectionLink": "Collection: <CollectionLink />",
    "instanceIdLink": "ID: <InstanceLink />",
    "instanceTokenId": "Token ID",
    "instanceTitleSuffix": "(NFT Instance)",
    "imagePreview": "Image preview",
    "metadata": "Metadata",
    "noMetadata": "There is no metadata on record for this NFT.",
    "noPreview": "No preview available",
    "openInFullscreen": "Open in fullscreen",
    "openInNewTab": "Open in a new tab",
    "owner": "Owner",
    "ownerLink": "Owner: <OwnerLink />",
    "switchBackgroundColor": "Switch between dark and light background"
  },
  "nodes": {
    "title": "Active nodes",
    "unknown": "Consensus layer indexer is not caught up with the latest blocks. The number of active nodes is unknown.",
    "tooltip": "Blockchain nodes are authorized devices that track the distributed ledger and serve as communication hubs in the network.",
    "value": "{{value, number}}"
  },
  "errors": {
    "code": "error code",
    "module": "module",
    "error": "Error",
    "loadFirstPage": "load the first page",
    "unknown": "Unknown error",
    "unsupportedLayer": "Unsupported layer",
    "unsupportedNetwork": "Unsupported network",
    "invalidAddress": "Invalid address",
    "invalidBlockHeight": "Invalid block height",
    "invalidPageNumber": "Invalid page number",
    "invalidProposalId": "Invalid proposal ID",
    "invalidTxHash": "Invalid transaction hash",
    "notFoundBlockHeight": "Block not found",
    "notFoundTx": "Transaction not found",
    "notFoundProposal": "Proposal not found",
    "pageDoesNotExist": "The page you are looking for does not exist.",
    "validateURL": "Please validate provided URL",
    "validateURLOrGoToFirstPage": "Please check the URL or <FirstPageLink />.",
    "invalidUrl": "Invalid URL",
    "invalidVote": "Invalid vote",
    "revertedWithoutMessage": "reverted without a message",
    "storage": "Access to browser storage denied"
  },
  "footer": {
    "mobileTitle": "OPF",
    "title": "Oasis Protocol Foundation",
    "version": "Version: <ReleaseLink>{{version}}</ReleaseLink> (commit: <CommitLink>{{sha}}</CommitLink>) built at {{buildTime}}",
    "apiDocs": "API Documentation",
    "github": "GitHub"
  },
  "learningMaterials": {
    "consensus": {
      "header": "What is the Consensus layer?",
      "description": "Oasis Core is designed around the principle of modularity. The consensus layer is an interface that provides a number of important services to other parts of Oasis Core.",
      "transactions": "How do transactions work?",
      "transactionsDescription": "The consensus layer uses a common transaction...",
      "services": "What services are required?",
      "servicesDescription": "Each consensus backend needs to provide the following...",
      "genesis": "Genesis Document",
      "genesisDescription": "The genesis document contains a set of parameters that outline the initial state of the consensus layer and its services. For more details about the actual genesis document's API, see genesis API documentation.",
      "vectors": "Transaction Test Vectors",
      "vectorsDescription": "In order to test transaction generation, parsing...",
      "comet": "CometBFT",
      "cometDescription": "The CometBFT consensus backend lives in ..."
    },
    "emerald": {
      "header": "What is the Emerald network?",
      "description": "The Emerald ParaTime is our official EVM Compatible ParaTime providing smart contract environment with full EVM compatibility.",
      "gateway": "Web3 Gateway",
      "gatewayDescription": "To get started building on our Emerald ParaTime, you can use our public Web3 gateway, fully compatible with Ethereum's Web3 gateway."
    },
    "sapphire": {
      "header": "What is the Sapphire network?",
      "description": "The Sapphire ParaTime is our official confidential EVM Compatible ParaTime providing a smart contract development environment with EVM compatibility."
    },
    "header": "Learning materials",
    "hardhat": {
      "header": "Add the {{ layer }} Testnet to Hardhat",
      "description": "Open up your hardhat.config.ts and drop in these lines."
    },
    "pontusxdevnet": {
      "1": {
        "header": "What is the Pontus-X Network?",
        "description": "The Pontus-X Network is our official confidential EVM Compatible ParaTime offering a smart contract development environment that is compatible with the Ethereum Virtual Machine (EVM) focused on building a Federated Data Economy."
      },
      "2": {
        "header": "Devnet Parameters",
        "description": "The Devnet for Pontus-X may undergo frequent version upgrades."
      },
      "3": {
        "header": "Adding the Pontus-X Devnet to Hardhat",
        "description": "To integrate with Pontus-X devnet, open your hardhat.config.ts file and insert the following configurations."
      }
    },
    "pontusxtestnet": {
      "1": {
        "header": "What is the Pontus-X Network?",
        "description": "The Pontus-X Network is our official confidential EVM Compatible ParaTime offering a smart contract development environment that is compatible with the Ethereum Virtual Machine (EVM) focused on building a Federated Data Economy."
      },
      "2": {
        "header": "Testnet Parameters",
        "description": "The Testnet for Pontus-X may undergo frequent version upgrades."
      },
      "3": {
        "header": "Adding the Pontus-X Testnet to Hardhat",
        "description": "To integrate with Pontus-X testnet, open your hardhat.config.ts file and insert the following configurations."
      }
    },
    "token": {
      "header": "Token Metrics and Distribution",
      "description": "Rose is the currency powering the Oasis network."
    },
    "testnet": {
      "header": "Testnet parameters",
      "description": "The Testnet may be subject to frequent version upgrades."
    },
    "transfer": {
      "header": "How to Transfer ROSE into a ParaTime?",
      "description": "Rose is the currency powering the {{layer}} network."
    }
  },
  "pageHeader": {
    "ribbon": "You are viewing {{network}} data"
  },
  "pagination": {
    "first": "First",
    "last": "Last"
  },
  "social": {
    "description": "Be part of the community and stay in the loop on everything Oasis",
    "discord": "Discord",
    "header": "Join us",
    "reddit": "Reddit",
    "telegram": "Telegram",
    "twitter": "X",
    "youtube": "Youtube",
    "linkedin": "LinkedIn",
    "docs": "Documentation",
    "home": "Home"
  },
  "tableLayoutButton": {
    "changeView": "Change view"
  },
  "testnetFaucet": {
    "description": "Drops are limited to 10 TEST tokens",
    "header": "Oasis Testnet Faucet",
    "request": "Request test tokens"
  },
  "tokens": {
    "typeDescription": "{{description}} ({{spec}})",
    "emptyInventory": "Token has no inventory associated with it.",
    "emptyTokenHolderList": "There are no holders on record for this token.",
    "holders": "Holders",
    "holdersValue": "{{ value, number }}",
    "holdersCount": "Holders Count",
    "inventory": "Inventory",
    "totalSupply": "Total Supply",
    "totalSupplyValue": "{{ value, number }} {{ticker}}",
    "transferEventType": {
      "transfer": "Transfer",
      "approval": "Approval",
      "minting": "Minting",
      "burning": "Burning",
      "unavailable": "Unknown token transfer event"
    },
    "type": "Token Type"
  },
  "tokenSnapshot": {
    "header": "Token Snapshot"
  },
  "tokenPrice": {
    "header": "{{ticker}} Price"
  },
  "totalTransactions": {
    "header": "Total Transactions",
    "tooltip": "{{value, number}} total transactions"
  },
  "transactions": {
    "encryption": {
      "dataNonce": "Data Nonce",
      "encryptedData": "Data (encrypted)",
      "encryptedResult": "Result (encrypted)",
      "format": "Format",
      "plain": "Plain",
      "publicKey": "Public Key",
      "resultNonce": "Result Nonce"
    },
    "latest": "Latest Transactions",
    "method": {
      "stakingTransfer": "Transfer",
      "stakingAddEscrow": "Start delegate",
      "stakingReclaimEscrow": "Start undelegate",
      "stakingAmendCommissionSchedule": "Amend commission schedule",
      "stakingAllow": "Allowance Change",
      "stakingWithdraw": "Continued delegate",
      "roothashExecutorCommit": "Executor commit",
      "roothashExecutorProposerTimeout": "Executor proposer timeout",
      "registryRegisterEntity": "Register entity",
      "registryRegisterNode": "Register node",
      "registryRegisterRuntime": "Register runtime",
      "governanceCastVote": "Cast vote",
      "governanceSubmitProposal": "Submit proposal",
      "beaconPVSSCommit": "PVSSCommit",
      "beaconPVSSReveal": "PVSSReveal",
      "beaconVRFProve": "VRFProve",
      "unavailable": "Malformed transaction, method unavailable",
      "accounts": {
        "transfer": "Transfer"
      },
      "consensus": {
        "deposit": "Consensus Deposit",
        "delegate": "Consensus Delegate",
        "undelegate": "Consensus Undelegate",
        "withdraw": "Consensus Withdraw"
      },
      "evm": {
        "call": "Contract Call",
        "create": "Contract Creation"
      }
    }
  },
  "transaction": {
    "header": "Transaction",
    "warningMultipleTransactionsSameHash": "Please make sure you're looking at the right transaction. There is more than one transaction with this hash, which is extremely rare. We are showing the most recent successful one.",
    "rawData": "Raw Data",
    "tooltips": {
      "txTooltipEth": "Ethereum hash for the transaction",
      "senderTooltipEth": "Ethereum address for the sender",
      "recipientTooltipEth": "Ethereum address for the recipient",
      "txTooltipOasis": "Oasis hash for the transaction",
      "senderTooltipOasis": "Oasis address for the sender",
      "recipientTooltipOasis": "Oasis address for the recipient",
      "txTooltipHashUnavailable": "Hash not available in selected address format",
      "txEncrypted": "This transaction is encrypted.",
      "txNotEncrypted": "This transaction is <strong>not</strong> encrypted.",
      "senderTooltipUnavailable": "Sender not available in selected address format",
      "recipientTooltipUnavailable": "Recipient not available in selected address format"
    }
  },
  "runtimeEvent": {
    "cantLoadEvents": "Unfortunately we couldn't load the list of events. Please try again later.",
    "noEvents": "No events",
    "cantFindMatchingEvents": "We can't find any matching events.",
    "accountsburn": "Tokens burnt",
    "accountsmint": "Tokens minted",
    "accountstransfer": "Transfer",
    "consensusDeposit": "Deposit from consensus",
    "consensusWithdrawal": "Withdrawal to consensus",
    "consensusDelegate": "Delegate to consensus",
    "consensusUndelegateStart": "Start to undelegate from consensus",
    "consensusUndelegateDone": "Undelegate from consensus finished",
    "evmLog": "EVM log message",
    "filter": {
      "all": "All events",
      "nonTx": "Non-transactional"
    },
    "gasUsed": "Gas used",
    "fields": {
      "amount": "Amount",
      "data": "Data",
      "emittingContract": "Emitting contract",
      "topics": "Topics",
      "owner": "Owner",
      "activeShares": "Active Shares",
      "debondingShares": "Debonding Shares"
    }
  },
  "transactionStats": {
    "header": "Transactions Per Day",
    "perDay": "{{value}} tx/day",
    "perHour": "{{value}} tx/hour"
  },
  "select": {
    "placeholder": "Select"
  },
  "chartDuration": {
    "pastDay": "Past day",
    "pastWeek": "Past week",
    "pastMonth": "Past month",
    "pastYear": "Past year",
    "year": "Year",
    "week": "Week",
    "month": "Month",
    "lastHour": "Last hour",
    "lastDay": "Last day",
    "lastMonth": "Last month"
  },
  "currentFiatValue": {
    "title": "Current fiat value",
    "explanation": "This figure is calculated using the current rate. The value at the time of transaction might have been different."
  },
  "paraTimeSnapshot": {
    "header": "{{ paratime }} Snapshot"
  },
  "layerPicker": {
    "chainId": "Chain ID:",
    "decimal": "Decimal: {{ id }}",
    "comingSoonTitle": "Coming soon",
    "comingSoonLabel": "(coming soon)",
    "consensus": "The consensus layer is a scalable, high-throughput, secure, proof-of-stake consensus run by a decentralized set of validator nodes.",
    "goToDashboard": "Go to dashboard",
    "hex": "Hex: {{ id }}",
    "readMore": "Read more about {{ layer }} on {{ network }} in Oasis Docs",
    "rpcHttp": "RPC HTTP endpoint: {{ endpoint }}",
    "rpcWebSockets": "RPC WebSockets endpoint: {{ endpoint }}",
    "selected": "(active)",
    "viewNetworks": "View Networks",
    "viewLayers": "View layers",
    "mainnet": {
      "emerald": "The Emerald ParaTime is our official EVM Compatible ParaTime providing smart contract environment with full EVM compatibility.",
      "sapphire": "The Sapphire ParaTime is our official confidential EVM Compatible ParaTime providing a smart contract development environment with EVM compatibility."
    },
    "testnet": {
      "emerald": "The Testnet of the EVM Compatible ParaTime providing a smart contract development environment.",
      "sapphire": "The Testnet of the official confidential EVM Compatible ParaTime providing a smart contract development environment with EVM compatibility.",
      "pontusxdev": "The Devnet of Pontus-X, a confidential EVM Compatible ParaTime offering a smart contract development environment that is compatible with the Ethereum Virtual Machine (EVM) focused on building a Federated Data Economy.",
      "pontusxtest": "The Testnet of Pontus-X, a confidential EVM Compatible ParaTime offering a smart contract development environment that is compatible with the Ethereum Virtual Machine (EVM) focused on building a Federated Data Economy."
    }
  },
  "home": {
    "apiOffline": "Our {{ target }} API is offline. We’re trying to reconnect",
    "userOffline": "You are offline. We’re trying to reconnect",
    "layerOutOfDate": "We don't have fully up-to-date data about our {{ target }}. The data displayed here might be out of date.",
    "layerUnavailable": "Data about {{ target }} is currently not available. Please check back later.",
    "layerOutOfDateSince": "The last update we have about our {{ target }} is from {{ lastUpdate }}. The data displayed here might be out of date.",
    "blocks": "Blocks",
    "exploreBtnText": "Explore Oasis",
    "link": "Oasis Explorer Home",
    "txns": "Txns",
    "zoomOutBtnText": "Zoom out",
    "online": "Online",
    "offline": "Offline",
    "helpScreen": {
      "navigate": "Navigate using your finger",
      "pinch": "Pinch to zoom in and out",
      "tap": "Tap to view info",
      "getStarted": "Get Started",
      "infoIconAria": "Toggle info screen"
    },
    "tooltip": {
      "coming": "Coming soon",
      "openConsensus": "Open Consensus",
      "openParatime": "Open ParaTime",
      "sapphireParaTimeDesc": "Confidential EVM Compatible ParaTime providing a smart contract development environment with EVM compatibility",
      "emeraldParaTimeDesc": "EVM Compatible ParaTime providing smart contract environment with EVM compatibility.",
      "cipherParaTimeDesc": "The Cipher ParaTime is a Confidential ParaTime for executing Wasm smart contracts.",
      "pontusxParaTimeDesc": "Pontus-X ParaTime",
      "consensusDesc": "Our scalable, high-throughput, secure, proof-of-stake consensus layer run by a decentralized set of validator nodes.",
      "close": "Close"
    },
    "selectedNetwork": "Selected network",
    "coming": "Coming",
    "soon": "soon"
  },
  "paratimes": {
    "activeNodes": "{{nodes}} active",
    "blockNumber": "Block number:",
    "inactive": "Coming soon",
    "listTitle": "ParaTimes",
    "noData": "No data available",
    "nodes": "Nodes:",
    "online": "Online",
    "outdated": "Out-of-date"
  },
  "pontusx": {
    "devnet": "Pontus-X devnet",
    "testnet": "Pontus-X"
  },
  "search": {
    "placeholder": "Address, Block, Contract, Transaction hash, Token name, etc.",
    "error": {
      "tooShort": "Please enter either at least 3 characters or a number in order to perform a search.",
      "privacy": "It seems like you might accidentally entered a keyphrase for a wallet. Please note that your mnemonic is a secret key that should never be shared, even not with our {{ appName }}.\nExecuting this search is highly unlikely to return any results. If you want to proceed nonetheless, please add “{{ wordsOfPower }}” in front of your search query to perform the search at your own risk."
    },
    "mobilePlaceholder": "Search Address, Block, Txn, Token, etc",
    "noResults": {
      "header": "No results found",
      "scopeHeader": "No results found on {{scope}}",
      "description": "Please try another search query <OptionalBreak> or explore the <HomeLink>Oasis ecosystem</HomeLink>.</OptionalBreak>"
    },
    "otherResults": {
      "clickToShowThem": "We found <strong>{{ countLabel }}</strong> on other <strong>Networks</strong>. Click to open these results.",
      "clickToShowMore": "We also found <strong>{{ countLabel }}</strong> on other <strong>Networks</strong>. Click to open these results.",
      "clickToHide": "The results from other <strong>Networks</strong> are shown below. Click to hide those results."
    },
    "results": {
      "accounts": {
        "title": "Accounts",
        "viewLink": "View Account"
      },
      "blocks": {
        "title": "Blocks",
        "viewLink": "View Block"
      },
      "contracts": {
        "title": "Contracts",
        "viewLink": "View Contract"
      },
      "tokens": {
        "title": "Tokens",
        "viewLink": "View Token"
      },
      "transactions": {
        "title": "Transactions",
        "viewLink": "View Transaction"
      },
      "proposals": {
        "title": "Proposals",
        "viewLink": "View Proposal"
      },
      "count_one": "1 result",
      "count_other": "{{ count }} results",
      "moreCount_one": "1 more result",
      "moreCount_other": "{{ count }} more results"
    },
    "searchBtnText": "Search",
    "searchSuggestions": "Not sure what to look for? Try out a search: <OptionalBreak><BlockLink><BlockIcon/> Block</BlockLink>, <TransactionLink><TransactionIcon/> Transaction</TransactionLink>, <AccountLink><AccountIcon/> Address</AccountLink>, <TokenLink><TokenIcon/> Token</TokenLink> </OptionalBreak>.",
    "searchSuggestionsForNoResults": "Alternatively, you can view a random <BlockLink><BlockIcon/> Block</BlockLink>, <OptionalBreak><TransactionLink><TransactionIcon/> Transaction</TransactionLink>, <AccountLink><AccountIcon/> Address</AccountLink> or <TokenLink><TokenIcon/> Token</TokenLink></OptionalBreak> <OptionalBreak>to discover our Explorer.</OptionalBreak>",
    "wordsOfPower": "I COMMAND THEE TO SEARCH FOR"
  },
  "tableSearch": {
    "error": {
      "tooShort": "Please enter at least 3 characters to perform a search."
    },
    "noMatchingResults": "There are no results matching your filters.",
    "clearFilters": "Clear filters"
  },
  "validator": {
    "active": "Active",
    "amount": "Amount",
    "balanceDistribution": "Balance Distribution",
    "blockWithHeight": "Block {{height}}",
    "boundsNotSet": "No bounds set (0% - 100%)",
    "change": "Change (24h)",
    "commission": "Commission",
    "commissionBounds": "Commission Bounds",
    "commissionRates": "Commission Rates",
    "cumulativeVoting": "Cumulative Voting %",
    "debondingEnd": "Unlock Epoch",
    "delegations": "Delegations",
    "delegators": "Delegators",
    "emptyDebondingList": "No one is debonding delegations from this validator.",
    "emptyDelegationsList": "No one is delegating to this validator.",
    "entityId": "Entity ID",
    "externalLink": "External Link",
    "externalLinkDescription": "Oasis is not responsible for any content on external pages.",
    "groupStatus": "{{status}} validators",
    "inactive": "Inactive",
    "listTitle": "Validators",
    "missed": "Missed",
    "nodeId": "Node ID",
    "others": "Others",
    "signed": "Signed",
    "signedBlocks": "Signed Blocks",
    "signedBlocksDescription": "Last 100 blocks",
    "proposedBlocks": "Proposed Blocks",
    "snapshot": "Validator Snapshot",
    "self": "Self",
    "shares": "Shares",
    "stakingTrend": "Staking Trend",
    "startDate": "Start Date",
    "title": "Validator",
    "totalEscrow": " Total Escrow (100%)",
    "totalShare": "Total Share",
    "undelegations": "Undelegations",
    "uptime": "Uptime",
    "voting": "Voting %",
    "votingPower": "Voting Power",
    "votingPowerOverall": "in % of overall network",
    "waiting": "Waiting"
  }
}
